import React from 'react'
import { Col, Row } from 'antd'
import { GiTeePipe, GiArtificialHive, GiWoodStick, GiDna2 } from 'react-icons/gi'
import { FaUser } from 'react-icons/fa'

import { AiFillInstagram, AiFillMail } from 'react-icons/ai'

import { graphql } from 'gatsby'

import siteConfig from '../../data/siteConfig'

import { Tabs, About, About2, Sani, Hero, SEO, Wrapper, Timeline, GridImages, Intro } from '../components';

import 'antd/dist/antd.css'
import '../theme/index.scss'

import styles from './index.module.scss'

class Home extends React.Component {
//   static defaultProps = {
//     pageResources: {
//       json: {
//         data: {
//           experienceGallery: { images: [] },
//           hobbyGallery: { images: [] }
//         }
//       }
//     }
//   }

  render () {
    // const { loading, data, pageResources } = this.props;
    
    

    if (siteConfig.googleAnalyticsId === 'UA-000000000-1') {
      console.error('We do not use GA');
    }

    const title = siteConfig.siteTitle
    const {keywords} = siteConfig

    return (
      <>
        <SEO
          title={title}
          keywords={keywords}
        />

        <Hero
          heroImg={siteConfig.siteCover}
          title={title}
        />

        <Wrapper className={this.props.className} >
          <div className={styles.pageContent} fluid>
            <Row justify="center">
              <Col span={24} className={styles.avatar}>
                <img
                  // className={styles.image}
                  styles='width=400px'
                  src='/images/claudio.jpg'
                  alt='haustechnik und Glasbläser'
                />
              </Col>
            </Row>
            <Row>
              <Col md={12} lg={12} xs={24} >
                <About title='' text={siteConfig.impressum}/>
              </Col>
             
              <Col md={12} lg={12} xs={24} >
                <About2 title='' text={siteConfig.kontakt} />
              </Col>
            </Row>
            <Row style={{ textAlign:'center', margin:'0px 0 0 0'}} >
              <Col md={24} lg={24} xs={24} >

                <div>AGBs <br/>
                Allgemeine Geschäftsbedingungen (AGB) Version Januar 2023<br/>
Für Suissetec- Installationsbetriebe der Bereiche Spengler, Sanitär, Heizung, Lüftung, Kälte, Klima<br/><br/>
1. Die vorliegenden ABG bilden einen integrierenden Bestandteil des zwischen den Parteien vereinbarten Werkvertrages oder Auftrages.
<br/>
2. Es gelten die SIA Norm 118 und die SIA Norm 118/380, soweit deren Bestimmung nicht in Widerspruch mit den vorliegenden AGB stehen.
<br/>
3. Alle vom Unternehmen erstellen Offerten-Unterlagen und Skizzen bleiben in dessen Eigentum und dür- fen ohne seine schriftliche Zustimmung weder vervielfältigt noch Dritten zugänglich gemacht noch kom- merziell genutzt werden.
<br/>
4. Auf bestimmte Vertrags-Positionen gewährte Rabatte sind das Ergebnis einer individuellen Kalkulation. Als solche sind die konkreten Rabatte an die im Vertrag vereinbarten Mengen und Apparate bzw. Materi- alien gebunden.
<br/>
5. VorbehalteneinerausdrücklichenAbweichungenRegelungverstehensichallePreiseohneMehrwert- steuer.
<br/>
6. Wird ausdrücklich ein Pauschalpreis ausgehandelt und unmissverständlich als solcher bezeichnet, sind keine weiteren Abzüge mehr möglich.
<br/>
7. Transporte und Installationen von Badewannen über 100Kg, die einem Kran oder anderen Hilfsmittel er- fordern, werden zusätzlich in Rechnung gestellt.
<br/>
8. Der Vertragspartner anerkennt Suissetec als Stelle für die Gewährung von Solidarbürgschaften im Sinne von Art. 181 SIA Norm 118 und verzichtet darauf, einen Garantievertrag im Sinne von Art. 111 OR zu fordern.
<br/>
9. Mit der Abnahme des Werkes, der Übergabe der Schlussrechnung, dem Ablauf der Prüfungsfrist und nach Übergabe des Garantiescheins gemäß Art. 152 SIA 118 sind alle Rückbehaltsmöglichkeiten gemäß Art. 82 OR ausgeschlossen.
<br/>

10. Mahnungs- und Inkassogebühren für verfallende Rechnungen werden dem Kunden in Rechnung gestellt.
<br/>

11. Das erste Angebot ist kostenlos. Wird nach dem zweiten Angebot der Auftrag nicht erteilt, wird eine Pau-
schale von 500.- CHF für Aufwände in Rechnung gestellt. Bei Auftragserteilung entfallen diese Kosten.
<br/>

12. Bei Auftragserteilung wird eine Akontozahlung fällig. In der Regel 1/3 – 1/2 des Angebotes.
<br/>

13. Die gelieferte und installierte Ware bleibt bis zur vollständigen Bezahlung der Rechnung Eigentum der
De Martino GmbH.
<br/>

14. Veränderungen in den Verhältnissen des Käufers wie Zahlungsverzug oder Zahlungsschwierigkeiten be-
rechtigen die De Martino GmbH zum sofortigen Rücktritt von allfälligen Liefer- und Montageverpflich-
tungen. Allfällige Guthaben werden in solchen Fällen sofort zur Zahlung fällig.
<br/>

15. Der Eigentümer/Betreiber ist verantwortlich für den Unterhalt der Gebäudeverteilung, Apparate und Ar-
maturen. Er hat die Pflicht, für eine regelmäßige Funktionskontrolle und nach längeren Abwesenheiten
für ein kräftiges Spülen der Kalt- und Warmwasserleitung an allen Entnahmestellen.
<br/>

16. Von diesen AGB abweichende Bestimmungen sind nur gültig, wenn diese Abweichungen schriftlich fest-
gehalten werden.
<br/>

17. Mit der Auftragserteilung anerkennt der Vertragspartner diese ABG als verbindlich.
<br/>

18. Der Gerichtstand für alle Parteien befindet sich am Sitz des Unternehmens. Es gilt das schweizerische
Recht.
<br/>

19. Es gelten Tagespreise<br/>

Bitte ein Exemplar der allgemeinen Geschäftsbedingung unterzeichnet zurücksenden per mail <a href="mailto:claudio@de-martino.ch">claudio@de-martino.ch</a> oder Post: De Martino GmbH, Glattstegweg 95, 8051 Zürich
<br/>

 
                </div>

              </Col>
              </Row>
            <div className={styles.separator} />
            
          </div>
        </Wrapper>
        <Wrapper>
        <Row style={{margin:'-20px -20px 0 0', textAlign: 'center'}} >
              <Col md={12} lg={12} xs={24} >
                <div>Email: <a href="mailto:claudio@de-martino.ch">claudio@de-martino.ch</a></div>
              </Col>
              <Col md={12} lg={12} xs={24} >
              <div>Handy: <a href="tel:076 454 00 04">076 454 00 04</a></div>
              </Col>
              </Row>
              <Row style={{textAlign:'center', padding:'20px'}}>
                <Col md={24} lg={24} xs={24} >
                  <a >Impressum</a>
                </Col>
              </Row>
      </Wrapper>
      </>

     
    )
  }
}



export default Home
